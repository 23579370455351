/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_product-multishop.png"
      title="Gérez tous vos points de vente depuis une interface unique"
      name="Multiboutique"
      label="Multiboutique"
      description="Gardez le contrôle sur vos stocks et vos activités commerciales grâce à notre plateforme multiboutique. Depuis le compte administrateur, gérez l'ensemble de vos boutiques physiques. "
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-multishop_catalog.png',
              title: 'Catalogue centralisé',
              content: `Créez des fiches produits centralisées sur l'ensemble des boutiques`,
            },
            {
              media: 'icon_product-multishop_stock.png',
              title: 'Gestion des stocks ',
              content:
                'Contrôlez vos stocks et transférez des produits entre vos lieux de stockage',
            },
            {
              media: 'icon_product-business_purchasemanagement.png',
              title: 'Gestion commande fournisseur',
              content: 'Gardez le contrôle et suivez avec précision vos achats',
            },
            {
              media: 'icon_product-multishop_performanceanalysis.png',
              title: 'Suivi des performances ',
              content: `Suivez les indicateurs d'activité de vos boutiques et prenez les meilleures décisions`,
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_multishop_catalog.png',
              alt: 'catalogue centralisé',
              label: 'Catalogue centralisé',
              title: `Visualisez d'un coup d'oeil l'ensemble de vos produits`,
              content: `Avec Wino, bénéficiez d'un catalogue homogène et organisé pour gérer les multiples références de vos différents points de vente. Avec des fiches produit spécifiques pour le vin, la bière, les spiritueux et les produits d'épicerie fine, la possibilité de créer des variantes, la mise en place d'un système de catégories et de SKU, vos équipes maîtrisent la gestion d'un catalogue centralisé pour l'ensemble de vos boutiques. Vous limitez les erreurs de stock et suivez des milliers de produits en temps réel.`,
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_multishop_stock.png',
              alt: 'gestion des stocks',
              label: 'Gestion des stocks',
              title: 'Assurez une gestion optimale de vos stocks ',
              content:
                "Grâce au catalogue centralisé, vous pourrez suivre les mouvements de stock entrants et sortants d'un produit pour l'ensemble de vos boutiques et visualiser les stocks globaux de l'entreprise et par lieu de stockage. Vous pourrez également effectuer des transferts de stock entre vos différentes boutiques pour obtenir des niveaux de stock cohérents entre les lieux de vente. Travaillez sereinement avec vos équipes en maîtrisant les flux entrants et sortants et évitez le sur-stockage et le sous-stockage.",
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'security',
              title: 'Souscrivez un abonnement entrepôt',
              content: `Vous disposez de plusieurs boutiques mais également d'un entrepôt pour stocker vos produits ? Grâce à la solution Entrepôt de Wino, gérez vos stocks en boutique et dans votre entrepôt, créez des transferts de produits et des commandes fournisseurs en toute simplicité. `,
            },
            {
              media: 'compliance',
              title: `Nos clients l'utilisent`,
              content: `Avec deux boutiques physiques, un entrepôt et un site e-commerce, la Cave Spirituelle doit centraliser ses activités pour être la plus efficace possible. Découvrez comment la solution multiboutique de Wino l'a aidé à répondre à sa problématique de gestion.`,
              linkTo: '/logiciel-de-caisse-enregistreuse-obligatoire-en-2018',
              linkText: `Lire l'article`,
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_multishop_supplierorder.png',
              alt: 'gestion commande fournisseur',
              label: 'Gestion commande fournisseur',
              title: 'Gérez vos achats en toute simplicité ',
              content:
                "Digitaliser la gestion de l'approvisionnement est cruciale pour le commerçant et d'autant plus si l'entreprise compte plusieurs lieux de vente et de stock. Grâce à l'outil Wino, les différents collaborateurs de l'entreprise pourront suivre les commandes passées, en cours et à venir, leurs statuts de réception et de paiement et dispatcher ensuite les commandes entre les différents points de vente.",
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_multishop_performanceanalysis.png',
              alt: 'suivi des performances',
              label: 'Suivi des performances',
              title: 'Faites le bilan à tout moment',
              content: `Grâce aux statistiques disponibles sur le tableau de bord, contrôlez les activités commerciales de vos magasins, ajustez votre stratégie et agissez avec efficacité. Avec la solution multiboutique, visualisez les performances globales de l'entreprise mais également par point de vente et comparez vos données : chiffre d'affaires, nombre de ventes effectuées et bien d'autres indicateurs pour assurer une bonne gestion de l'entreprise dans sa globalité.`,
            },
          ],
        },
      ]}
    />
  )
}
